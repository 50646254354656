.search-bar {
   background-color: white;
   border-radius: 5px;
   border: 3px solid rgba(120, 120, 120, 0.374);
   padding: 0.5rem 1rem;
   justify-content: space-between;
   /* width is mentioned in inline */
   width: 100%;
}

.search-bar>input {
   width: 60%;
   height: 40px;
   border: none;
   outline: none;
}

@media (max-width: 380px) {
   .search-bar>input {
      width: 50%;
   }
}