.h-wrapper {
   color: white;
   background-color: var(--black);
}

.h-container {
   justify-content: space-between;
   color: var(--secondary);
}

.h-container img {
   width: 100px;
}

.h-menu {
   gap: 2rem;
}

.h-menu a:hover {
   color: var(--blue);
}

/* [HandBurger] for responsive */
.menu-icon {
   display: none;
}

/* Media For Tab and mobiles */
@media (max-width: 768px) {
   .menu-icon {
      display: block;
   }

   .h-menu {
      color: var(--black);
      position: absolute;
      gap: 2rem;
      font-weight: 500;
      flex-direction: column;
      right: 3rem;
      top: 3rem;
      background: white;
      display: flex;
      border-radius: 10px;
      transition: all 200ms ease;
      align-items: flex-start;
      padding: 3rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      z-index: 2;
   }
}