.App {
  position: relative;
}

.App>:nth-child(1) {
  position: relative;
  background-color: var(--black);
}

.App .white-gradient {
  position: absolute;
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.522);
  filter: blur(100px);
}