.hero-wrapper {
   color: white;
   position: relative;
   padding-bottom: 2rem;
}

.hero-container {
   justify-content: space-around;
   align-items: flex-end;
}

/* right Side */
.image-container {
   width: 30rem;
   height: 35rem;
   overflow: hidden;
   border-radius: 15rem 15rem 0 0;
   border: 8px solid rgba(255, 255, 255, 0.12);
}

.image-container img {
   width: 100% !important;
   height: 100%;
}

/* left side */
.hero-left {
   gap: 3rem;
}

.hero-title {
   position: relative;
   z-index: 1;
}

.hero-title h1 {
   font-size: 3.8rem;
   font-weight: 600;
   line-height: 4rem;
}

.hero-title .orange-circle {
   height: 4rem;
   width: 4rem;
   background: var(--orange-gradient);
   border-radius: 50%;
   position: absolute;
   top: -10%;
   right: 30%;
   z-index: -1;
}

.stats {
   width: 100%;
   justify-content: space-between;
}

.stat>:nth-child(1) {
   font-size: 2rem;
}

.stat>:nth-child(1)>:last-child {
   color: orange;
}

@media (max-width: 640px) {
   .hero-container {
      /* margin-top: 2rem; */
   }

   .hero-title>h1 {
      font-size: 2.5rem;
      line-height: 3rem;
   }

   .image-container {
      width: 100%;
      height: 25rem;
   }

   .stats {
      justify-content: center;
      gap: 1.5rem;
      /* font-weight: wrap; */
   }

   .hero-right {
      width: 100%;
   }

   .stat>:nth-child(1) {
      font-size: 1.5rem;
   }

   .stat>:nth-child(2) {
      font-size: 0.8rem;
   }
}