.r-wrapper {
   padding: 6%;
}

.r-container {
   border-radius: 2rem;
   position: relative;
   overflow: hidden;
   border: 1px solid black;
}

.r-head {
   margin-bottom: 1.5rem;
}

.swiper-horizontal {
   overflow: visible !important;
}

.r-button {
   position: absolute;
   gap: 1rem;
   top: -4rem;
   right: 0;
}

.r-button button {
   font-size: 1.2rem;
   padding: 0.2rem 0.8rem;
   color: blue;
   border: none;
   border-radius: 5px;
   background-color: white;
   cursor: pointer;
}

.r-button>:nth-child(1) {
   background-color: #EEEEFF;
}

.r-button>:nth-child(2) {
   box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
}

.r-button>:nth-child(2):hover,
.r-button>:nth-child(1):hover {
   scale: 1.10;
}

@media (max-width: 640px) {
   .r-head {
      align-items: center;
   }

   .r-button {
      position: relative;
      top: 0.5rem
   }
}