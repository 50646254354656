.f-wrapper {
   margin: 150px 0 100px 0;
}

.f-container {
   justify-content: space-between;
   border-top: 5px solid rgba(0, 0, 0, 0.148);
}

.f-left {
   gap: 1rem;
}

.f-menu {
   gap: 1.5rem;
   margin-top: 1.5rem;
   font-weight: 500;
}

@media (max-width: 768px) {
   .f-container {
      justify-content: center;
   }

   .f-container>div {
      align-items: center !important;
      text-align: center;
   }

   .f-right {
      justify-content: center;
   }
}