.r-card {
   gap: 0.6rem;
   padding: 1rem;
   border-radius: 10px;
   max-width: max-content;
   margin: auto;
   transition: all 300ms ease-in;
   position: relative;
   z-index: 0;
}

.r-card>svg {
   position: absolute;
   top: 25px;
   right: 30px;
   z-index: 1;
}

.r-card>img {
   width: 100%;
   max-width: 15rem;
}

.r-card>:nth-child(3) {
   font-size: 1.2rem;
   font-weight: 600;
}

.r-card>:nth-child(4) {
   font-size: 1.5rem;
}

.r-card>:nth-child(5) {
   font-size: 0.7rem;
   width: 15rem;
}

.r-card:hover {
   scale: 1.025;
   cursor: pointer;
   background: linear-gradient(180deg,
         rgb(255, 255, 255, 0) 0%,
         rgb(136, 160, 255, 0.5)217.91%);
   box-shadow: 0px 72px 49px -51px rgba(136, 160, 255, 0.21);
}